import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Jumbotron } from "reactstrap";
import Home from "./components/Home/Home";
import LoginForm from "./components/login";
import AppLogout from "./components/Logout";
import RegisterForm from "./components/register";

import AppNavbar from "./Header/NavBar";
import ProtectedRoute from "./Helpers/ProtectedRoute";
import Movies from "./Apollo/Apollo";
import Codementor from "./Codementor";
import criticalBuildings from "./components/Critical Buildings/Critical Buildings";
import FAQ from "./components/FAQ";
import Guide from "./components/Guide";

const AppRouter = () => {
  return (
    <>
      <AppNavbar />
      <Container className="d-flex flex flex-column align-items-center">
        <Switch>
          <Route path="/" exact>
            <ProtectedRoute component={Home} />
          </Route>
          <Route path="/CriticalBuildings" exact>
            <ProtectedRoute component={criticalBuildings} />
          </Route>
          <Route path="/mentor" component={Codementor} />
          <Route path="/login">
            <Jumbotron className="d-flex flex flex-column col-sm-12 my-5 py-0 align-items-center">
              <LoginForm />
            </Jumbotron>
          </Route>
          <Route path="/register">
            <Jumbotron className="d-flex flex flex-column col-sm-12 my-5 py-0 align-items-center">
              <RegisterForm />
            </Jumbotron>
          </Route>
          <Route path="/logout">
            <AppLogout />
          </Route>
          <Route path="/guide">
            <Jumbotron
              style={{ background: "transparent" }}
              className="d-flex flex flex-column col-sm-12 my-5 py-0 align-items-center"
            >
              <Guide />
            </Jumbotron>
          </Route>
          <Route path="/faq">
            <Jumbotron
              style={{ background: "transparent" }}
              className="d-flex flex flex-column col-sm-12 my-5 py-0 align-items-center"
            >
              <FAQ />
            </Jumbotron>
          </Route>
          <Route path="/movies" component={Movies} />
          <Route path="*" component={() => "404 Not found"} />
        </Switch>
      </Container>
    </>
  );
};

export default AppRouter;

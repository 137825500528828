import { connect } from "react-redux";
import { Label } from "reactstrap";


const Note = () => {
  return (
    <p style={{ fontSize: '12px', fontWeight: 'bold', color: 'gray', marginTop: '5px', WebkitTextFillColor: "#FFFFFF"}}>
      *Note: If the proportions of the objects are inconvenient (e.g. if objects overlap) use the zoom commands of your 
      browser to zoom in or out
      (the command for Windows users are "Control" + "+" or "-" and for Mac users are "Command" + "+" or "-").</p>
  );
};
const Home = ({ user, isAuthenticated }) => {
  return (
    <div>
    <Note />
    <iframe  src='https://s2ts-building-ranking-1.herokuapp.com/'></iframe>
    </div>

  );
};


const mapStateToProps = (state) => ({
  user: state.user.user,
  isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(Home);

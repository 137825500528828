import { connect } from "react-redux";
import Faq from "react-faq-component";
const data = {
  title: "FAQ (How it works)",
  rows: [
    {
      title: "Who is behind this project?",
      content:
        "The S2TS lab, from the Mechanical Engineering department of University of Maryland.",
    },
    {
      title: "Can multiple users have different levels of authority?",
      content:
        "Yes. Based on the login credentials, different levels of access can be incorporated.",
    },
    {
      title: "Can anyone edit the information shown?",
      content:
        "No. All the data and processes are server-sided and by logging in, they can not be meddled with.",
    },
    {
      title: "Is the information secure?",
      content:
        "Yes. Unless having login credentials, no one other than certain lab members can access the information.",
    },
    {
      title: "How Login Works",
      content:
        "Login works in regards with user type. Depending on the user type nad role the application grants necessary access to the user.",
    },
    {
      title: "How can someone log in?",
      content:
        "Login credentials would have to be provided to you from the S2TS lab.",
    },
    {
      title: "Software is too crowded and elements overlap, what to do?",
      content:
        "Please zoom in or out through your browser by pressing 'Control' + '+' / '-' (for Windows users) or 'Command' + '+' / '-' (for Mac users). <br> Also, please either use Chrome or Firefox.",
    }
  ],
};
const FAQ = () => {
  return (
    <div style={{ marginTop: "20px", textAlign: "left"}}>
      <Faq data={data} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(() => ({}), mapDispatchToProps)(FAQ);

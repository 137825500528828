import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

const AppNavbar = ({ isAuthenticated }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);
  // const { firstName, lastName } = user;
  return (
    <Navbar light expand="md" style={{ backgroundColor: "#000080" }}>
      <NavbarBrand>
        <img className="logo" src="/s2ts.png" />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNav} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto gap-15" navbar>
          {!isAuthenticated ? (
            <></>
          ) : (
            <NavItem>
              <NavLink>
                <Link to="/" style={{ WebkitTextFillColor: "#FFFFFF" }} >Home</Link>
              </NavLink>
            </NavItem>
          )}
          {!isAuthenticated ? (
            <></>
          ) : (
            <NavItem>
              <NavLink>
                <Link to="/guide" style={{ WebkitTextFillColor: "#FFFFFF" }} >Guide</Link>
              </NavLink>
            </NavItem>
          )}
          {!isAuthenticated ? (
            <></>
          ) : (
            <NavItem>
              <NavLink>
                <Link to="/faq" style={{ WebkitTextFillColor: "#FFFFFF" }} >FAQ</Link>
              </NavLink>
            </NavItem>
          )}
        </Nav>
        {isAuthenticated ? (
          <NavLink>
            <Link to="/logout" style={{ WebkitTextFillColor: "#FFFFFF" }}>Logout</Link>
          </NavLink>
        ) : (
          <>
            <NavLink>
              <Link to="/login" style={{ WebkitTextFillColor: "#FFFFFF" }} >Login</Link>
            </NavLink>
            <NavLink className="ml-10">
              <Link to="/register" style={{ WebkitTextFillColor: "#FFFFFF" }} >Register</Link>
            </NavLink>
          </>
        )}
      </Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps)(AppNavbar);

import { connect } from "react-redux";

const Guide = () => {
  return (
    <body class="Guide">
    <div style={{ marginTop: "20px", textAlign: "left" }}>
      The features&rsquo; locations have been marked below. The explanation for
      each one is pro
      <img
        style={{ width: "100%" }}
        src="/image.png"
        alt="frame"
        srcset="/image.png"
      />
      <br />
      Features:
      <br />
      <br />
      1. Building Type Selector: By clicking on the gray tab, a scroll-down list
      appears. User can either choose all buildings to show or only buildings
      with specific usage type. If a specific building type is chosen, a
      vertical purple line is also shown, which marks the Energy Star benchmark
      EUI for the building type.
      <br />
      <br />
      Note: For some of the building types (e.g. in the provided version,
      &ldquo;Hanger&rdquo;), no buildings are of that type. If one those
      building
      <br />
      types get chosen, nothing will happen.
      <br />
      2. Y-Axis Selector: Select which criteria to be shown as the Y-Axis. The
      plot will show selected Y-Axis vs EUI.
      <br />
      <br />
      3. EUI Standing Filter: This Filter allows users to include buildings that
      have a EUI standing (represented by colors) worse than what is chosen. The
      EUI quality of a building compares how good or bad it is doing with
      respect to the Energy Star benchmark for that building type. The EUI
      quality is shown by color (see section 7 for colors definition).
      <br />
      <br />
      4. Y-Axis Range Selector: Since a wide variety of buildings are shown in
      the plot, the values can differ significantly. This feature
      <br />
      <br />
      allows the user to specify a range to better see the desired range.5. Year
      selector: The user can choose the year range, and the numbers are
      calculated based on the average of buildings
      <br />
      <br />
      consumption for that year range.
      <br />
      <br />
      6. Symbols: Campuses are differentiated by symbols. By clicking each
      symbol on the legend, user can exclude/include the
      <br />
      <br />
      campus, and by double clicking, the symbol only the selected campus would
      be included (double click again to include all the campuses).
      <br />
      <br />
      7. EUI Standing: A color will be determined for each building based on how
      it is doing compared to its respective Energy Star benchmark (based on
      building usage type). The coloring is as follows:
      <br />
      <br />
      By default, the range divider (X) is 20%, but the value can be changed by
      typing the desired value in the box below.
      <br />
      <br />
      Green: Building has a EUI of (100 &ndash; 2*X)% or lower than the
      benchmark (considered very good)
      <br />
      <br />
      Lime: Building has a EUI between (100 &ndash; 2*X)% and (100 &ndash; X)%
      of the benchmark (considered good)
      <br />
      <br />
      Yellow: Building has a EUI between (100 &ndash; X)% and (100 + X)% of the
      benchmark (considered on par with the benchmark)
      <br />
      <br />
      Orange: Building has a EUI between (100 + X)% and (100 + 2*X)% of the
      benchmark (considered bad)
      <br />
      <br />
      Red: Building has a EUI of (100 + 2*X)% or higher than the benchmark
      (considered very bad)
      <br />
      <br />
      8. Plot:
      <br />
      <br />
      - By hovering over each point, various information about the building, and
      a pie chart of energy types show up.
      <br />
      <br />
      - By clicking and dragging the mouse, the plot will zoom in on the region.
      <br />
      - By double-clicking a white space, the plot will zoom out to the default
      view.
      <br />
      <br />
      - By clicking a point, a report of that building will be downloaded.
      <br />
      <br />
      - The vertical yellow line is the average EUI of all the buildings shown
      in the plot.
      <br />
      <br />
      - The blue horizontal line is the average Y-axis value of all the
      buildings shown in the plot.
      <br />
      <br />
      Note: At the moment, a basic report will be downloaded by clicking on a
      point. This report will improve in the future.
      <br />
      <br />
      9. Map plot: by hovering over each point on the plot, the building&rsquo;s
      location on the map will be shown as a grey dot here. By hovering the
      mouse over the blue dot, geographical information such as address,
      latitude and longitude will show up. address, latitude and longitude will
      show up.
      <br />
      Note: Since campuses are multiple buildings, they don&rsquo;t have a
      single address or latitude and longitude. For campuses, address shows up
      as null on hover data, and longitude and latitude are averages of the
      buildings on the campus.10. By hovering over each point on the plot,
      picture of that building shows up here
      <br />
      <br />
      Note: we did not have pictures of all the buildings, so we used the
      pictures of the buildings we had for all of them and therefore you will
      see a picture that is not actually that building. The pictures will become
      accurate in the future.
      <br />
      <br />
      11. Fossil Fuel Filter: By checking the box, buildings who consume less
      fossil fuel than specified will be excluded from the plot.
      <br />
      Note: At the moment, the specified value is 1000 MMBtu, but in the future
      this number can be deliberately changed.
      <br />
      12. Dark View: By switching the toggle, the theme will change to dark
      colors.
    </div>
    </body>
  );
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(() => ({}), mapDispatchToProps)(Guide);
